// service.ts
import axios, { CancelTokenSource } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Equipment, FetchBPLiveDataPayload, GeneratorListPayload, GraphData, GraphDataPayload, LiveDataItem } from '../types/powerTypes';

let cancelTokenSource5: CancelTokenSource | null = null;
const protocol: any = process.env.REACT_APP_PROTOCOL;
const url: any = process.env.REACT_APP_API_URL;
const baseUrl = protocol + url;


export const fetchGeneratorList = createAsyncThunk<
    Equipment[], // Return type
    GeneratorListPayload, // Argument type
    { rejectValue: string | { canceled: boolean } } // Reject type
>(
    'sites/fetchGeneratorList',
    async (payload, thunkAPI) => {
        const { selectedCustomerName, avail_sites, selectedSite } = payload;

        if (cancelTokenSource5) {
            cancelTokenSource5.cancel('Request canceled');
        }
        cancelTokenSource5 = axios.CancelToken.source();

        const findSiteId = (siteName: string) =>
            avail_sites.find((site) => site.name === siteName);

        const site = findSiteId(selectedSite);

        if (!site) {
            return thunkAPI.rejectWithValue('Site not found');
        }

        try {
            const { data } = await axios.get<Equipment[]>(
                `${baseUrl}crud/site_equipments/${site.id}/`,
                {
                    withCredentials: true,
                    headers: {
                        customer: selectedCustomerName,
                    },
                    cancelToken: cancelTokenSource5.token,
                }
            );
            return data;
        } catch (error: any) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
                return thunkAPI.rejectWithValue({ canceled: true });
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

let cancelTokenSource4: CancelTokenSource | null = null;

export const fetchAndFilterGraphData = createAsyncThunk<
    GraphData[], // Return type (array of GraphData)
    GraphDataPayload, // Argument type (payload)
    { rejectValue: string } // Reject value type
>(
    'sites/fetchAndFilterGraphData',
    async (payload, thunkAPI) => {
        const { selectedCustomerName, avail_sites, selectedSite, fromDate, toDate, bpValue, selectedGeneratorId } = payload;

        if (cancelTokenSource4) {
            cancelTokenSource4.cancel('Request canceled');
        }

        cancelTokenSource4 = axios.CancelToken.source();

        const findSiteId = (siteName: string) =>
            avail_sites.find((site) => site.name === siteName);

        const site = findSiteId(selectedSite);

        if (!site) {
            return thunkAPI.rejectWithValue('Site not found');
        }

        // Setting default fromDate and toDate as today's date
        const currentDate = new Date();
        const startDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate()
        );
        const endDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            23,
            59,
            59,
            999
        );

        const postData = {
            fromrange: fromDate === undefined ? startDate.toISOString() : fromDate,
            torange: toDate === undefined ? endDate.toISOString() : toDate,
            value_name: bpValue === "Fuel Level"
                ? (selectedCustomerName === "bc-hydro" || selectedCustomerName === "fairfield"
                    ? "Fuel Level (%)"
                    : bpValue)
                : bpValue,
            source_id: "bluepillar"
        };

        try {
            const { data } = await axios.post<GraphData[]>(
                `${baseUrl}trend/graphdata/${site.id}/${selectedGeneratorId}/`,
                postData,
                {
                    withCredentials: true,
                    headers: {
                        customer: selectedCustomerName,
                    },
                    cancelToken: cancelTokenSource4.token,
                }
            );
            return data;
        } catch (error: any) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
                return []; // Return an empty array if the request is canceled
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

let cancelTokenSource6: CancelTokenSource | null = null;


export const fetchBPLiveData = createAsyncThunk<LiveDataItem[], FetchBPLiveDataPayload, { rejectValue: string }>(
    'sites/fetchBPLiveData',
    async (payload, thunkAPI) => {
        const { selectedCustomerName, avail_sites, selectedSite, selectedGeneratorId } = payload;

        if (cancelTokenSource6) {
            cancelTokenSource6.cancel('Request canceled');
        }

        cancelTokenSource6 = axios.CancelToken.source();

        const FindSiteid = (selectedSite: string) =>
            avail_sites.find((x) => x.name === selectedSite);
        const siteid = FindSiteid(selectedSite);

        if (!siteid) {
            return thunkAPI.rejectWithValue('Site ID not found');
        }

        try {
            const { data } = await axios.get<LiveDataItem[]>(
                `${baseUrl}trend/live_values/${siteid.id}/${selectedGeneratorId}/`,
                {
                    withCredentials: true,
                    headers: {
                        'customer': selectedCustomerName,
                    },
                    cancelToken: cancelTokenSource6.token,
                }
            );
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

let siteid: any = 0;
let postData;

export const fetchBPValues = createAsyncThunk<any[], FetchBPLiveDataPayload>('sites/fetchBPValues', async (payload, thunkAPI) => {
    const { selectedCustomerName, avail_sites, selectedSite, selectedGeneratorId } = payload;
    const FindSiteid = (selectedSite: string) =>
        avail_sites.find((x) => x.name === selectedSite);
    siteid = FindSiteid(selectedSite);
    postData = {
        source_id: "bluepillar",
    };
    try {
        const { data } = await axios.post(`${baseUrl}trend/values/${siteid.id}/${selectedGeneratorId}/`, postData, {
            withCredentials: true, headers: {
                'customer': selectedCustomerName, // Include the customer attribute in the headers
            }
        });
        return data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.message);
    }
})
