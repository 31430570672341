import { Card, Col, Row, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootreducer';

export default function StarLink() {
    const customerSites = useSelector((state: RootState) => state.userInfo.customerSites);

    const selectedSite = useSelector((state: RootState) => state.userInfo.selectedSite);

    const starLink = customerSites.find(site => site.name === selectedSite)?.communication_link;


    return (
        <React.Fragment>
            <Card title="Starlink Communication">
                <iframe
                    title="starlink-iframe"
                    style={{ width: "100%", height: "75vh", border: "none", backgroundColor: "none" }}
                    src={starLink}
                ></iframe>
            </Card>
        </React.Fragment>
    );
}
