import { Table } from "antd";
import { EbImageList } from './CameraTools';
import "antd/dist/reset.css";

// Define types for the records and columns
interface Record {
    id: string;
    createdAt: string;
    cameraName: string;
    picturePath: string;
    title?: string
}

interface Column {
    title: string;
    key: string;
    render: (record: Record) => JSX.Element;
}

function uniqueDays(dateTimeList: Record[]): string[] {
    const uniqueDates = new Set<string>();
    dateTimeList.forEach(row => {
        const date = new Date(row.createdAt);
        const dateString = date.toISOString().split('T')[0];
        uniqueDates.add(dateString);
    });
    return Array.from(uniqueDates);
}

function filterRecordsByDate(records: Record[], targetDate: string): Record[] {
    return records.filter(row => {
        if (row === null || row.createdAt === null) return false;
        const recordDate = new Date(row.createdAt).toISOString().split('T')[0];
        return recordDate === targetDate;
    });
}

function GenerateTables(rows: Record[], cols: Column[]) {
    return (
        <Table
            showHeader={true}
            style={{ width: "auto" }}
            columns={cols}
            dataSource={rows}
            rowKey={(record) => record.id}
            scroll={{ x: true }}
        />
    );
}

export function ConvertDate(inputDate: string): string {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    let hours = date.getHours();
    const amPM = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${month}/${day} - ${hours}:${minutes}:${seconds} ${amPM}`;
}

export function popuplateTable(fullList: Record[], uniqueListOfDays: string[]) {
    const columns: Column[] = [];
    const rows: Record[] = [];
    const maxImagesPerRow = 8;
    let pictureIndex = 0;

    uniqueListOfDays.forEach(day => {
        const recFilterDay = filterRecordsByDate(fullList, day);
        const collapseName = `${day} -- ${recFilterDay.length}`;

        recFilterDay.forEach((record, fDayIndex) => {
            const rowData: Record = {
                id: `${pictureIndex++}`,
                title: `${collapseName}${fDayIndex}`,
                createdAt: record.createdAt,
                cameraName: record.cameraName,
                picturePath: record.picturePath,
            };

            rows.push(rowData);
        });

        const col: Column = {
            title: "Images",
            key: "images",
            render: (record: any) => <EbImageList url={[record.picturePath]} />,
        };

        columns.push(col);
    });

    return GenerateTables(rows, columns);
}
