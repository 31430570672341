import { Button, Card, Image, Radio } from "antd";
import React from "react";


const contentStyle: React.CSSProperties = {
    margin: 0,
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
};

const onChange = (currentSlide: number) => {
    console.log(currentSlide);
};

export const CameraSearchToolBar = (props: any) => {
    return (
        <React.Fragment>
            <Card
                size="small"
                style={{ minHeight: "117px" }}
                className="cardUnselected"
                title="Filter"
            >
                &nbsp;&nbsp;
                <Radio.Group defaultValue="small">
                    <Radio.Button value="large">Start of day</Radio.Button>
                    <Radio.Button value="middle">Latest First</Radio.Button>
                </Radio.Group>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button onClick={() => props.CameraCardFunctions.refreshDataSet()}>
                    Refresh
                </Button>
            </Card>
        </React.Fragment>
    );
};

const boxStyle: React.CSSProperties = {
    width: "100%",
    height: 120,
    borderRadius: 6,
    border: "1px solid #40a9ff",
};

// Image List component
export const EbImageList: React.FC<{ url: { url: string; camera?: string; subtitle?: string }[] }> = (props) => {
    return (
        <>
            {props.url.map((row, index) => (
                <React.Fragment key={index}>
                    <Image height={100} width={150} src={row.url} />
                    {row.camera}
                    <br />
                    {row.subtitle}
                </React.Fragment>
            ))}
        </>
    );
};

// Single Image component
export const EbImage: React.FC<{ url: string; camera?: string; subtitle?: string }> = (props) => {
    return (
        <React.Fragment>
            <Image height={100} width={150} src={props.url} />
            {props.camera}
            <br />
            {props.subtitle}
        </React.Fragment>
    );
};

// Image Row component
export const EbImageRow: React.FC<{ row: { picturePath: string; cameraName: string; createdAt: string } }> = (props) => {
    return (
        <div style={{ marginLeft: 5 }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <div>
                    <Image height={100} width={150} src={props.row.picturePath} />
                </div>
                <div>
                    <div>{props.row.cameraName}</div>
                    <div>{props.row.createdAt}</div>
                </div>
            </div>
        </div>
    );
};
