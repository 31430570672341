// slice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Equipment, GraphData, LiveDataItem } from '../types/powerTypes';
import { fetchBPLiveData, fetchAndFilterGraphData, fetchGeneratorList, fetchBPValues } from '../services/powerService';

interface GeneratorListState {
    generatorList: Equipment[];
    graphData: GraphData[];
    liveDataArray: LiveDataItem[];
    fuelPercentage: number | undefined | null;
    loading: boolean;
    graphDataLoading: boolean;
    isLoadingBPLiveData: boolean;
    error: string | null;
    errorMessage: string | null;
    bpValue: string;
    canceled: boolean;
    bpValueArray: string[];
    isLoadingBPValues: boolean;
    selectedTimezone: string;
    selectedTimePeriod: string;
    selectedGeneratorId: number | null;
    generatorListError: string;
}

const initialState: GeneratorListState = {
    generatorList: [],
    bpValue: '',
    graphDataLoading: false,
    isLoadingBPValues: false,
    bpValueArray: [],
    isLoadingBPLiveData: false,
    selectedGeneratorId: null,
    graphData: [],
    liveDataArray: [],
    fuelPercentage: null,
    selectedTimePeriod: 'Today',
    loading: false,
    generatorListError: '',
    error: null,
    errorMessage: null,
    canceled: false,
    selectedTimezone: 'UTC',
};

const generatorListSlice = createSlice({
    name: 'generatorList',
    initialState,
    reducers: {
        clearGeneratorList(state) {
            state.generatorList = [];
            state.error = null;
            state.canceled = false;
        },
        clearPowerState() {
            return initialState
        },
        clearGraphData(state) {
            state.graphData = [];
            state.error = null;
            state.canceled = false;
        },
        setSelectedTimePeriod(state, action: PayloadAction<string>) {
            state.selectedTimePeriod = action.payload;
        },
        setFuelPercentage(state, action: PayloadAction<number | null | undefined>) {
            state.fuelPercentage = action.payload;
        },
        setSelectedTimezone(state, action: PayloadAction<string>) {
            state.selectedTimezone = action.payload;
        },
        setSelectedGeneratorId(state, action: PayloadAction<number | null>) {
            state.selectedGeneratorId = action.payload;
        },
        setBpValue(state, action: PayloadAction<string>) {
            state.bpValue = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGeneratorList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.canceled = false;
            })
            .addCase(fetchGeneratorList.fulfilled, (state, action: PayloadAction<Equipment[]>) => {
                state.loading = false;
                state.generatorList = action.payload;
                state.generatorListError = action.payload.length === 0 ? 'No generators available' : '';
            })
            .addCase(fetchGeneratorList.rejected, (state, action) => {
                state.loading = false;
                if (action.payload && (action.payload as any).canceled) {
                    state.canceled = true;
                } else {
                    state.error = action.payload as string;
                }
            })
            .addCase(fetchAndFilterGraphData.pending, (state) => {
                state.graphDataLoading = true;
                state.error = null;
            })
            .addCase(fetchAndFilterGraphData.fulfilled, (state, action: PayloadAction<GraphData[]>) => {
                state.graphData = action.payload;
                state.graphDataLoading = false;
            })
            .addCase(fetchAndFilterGraphData.rejected, (state, action) => {
                state.error = action.payload ?? 'Error fetching data';
                if (action.payload && (action.payload === 'Request canceled')) {
                    state.graphDataLoading = true;
                }
                else state.graphDataLoading = false;
            })
            .addCase(fetchBPLiveData.pending, (state) => {
                state.isLoadingBPLiveData = true;
            })
            .addCase(fetchBPLiveData.fulfilled, (state, action: PayloadAction<LiveDataItem[]>) => {
                state.isLoadingBPLiveData = false;
                state.liveDataArray = action.payload;
                state.fuelPercentage = state.liveDataArray.find(item => item.value_name === "Fuel Level")?.value ?? null;
            })
            .addCase(fetchBPLiveData.rejected, (state, action) => {
                state.errorMessage = action.payload as string;
                if (action.payload && (action.payload === 'Request canceled')) {
                    state.isLoadingBPLiveData = true;
                }
                else state.isLoadingBPLiveData = false;

            })
            .addCase(fetchBPValues.pending, state => {
                state.isLoadingBPValues = true;
            })
            .addCase(fetchBPValues.fulfilled, (state, action) => {
                state.isLoadingBPValues = false;
                state.bpValueArray = action.payload;
            })
            .addCase(fetchBPValues.rejected, (state, action) => {
                state.isLoadingBPValues = false;
                state.errorMessage = action.payload as string;
            });
    },
});

export const { clearPowerState, setFuelPercentage, clearGeneratorList, setSelectedTimePeriod, setSelectedTimezone, setBpValue, setSelectedGeneratorId, clearGraphData } = generatorListSlice.actions;

export default generatorListSlice.reducer;
