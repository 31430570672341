import { Card, Empty } from 'antd';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

export default function Help() {
    const { selectedCustomerName, customerSites,
        selectedSite } = useSelector((state: RootState) => state.userInfo);
    const confluenceLink = customerSites.find((site) => site.name === selectedSite)?.preferences?.confluence_link;


    // useEffect(() => {
    //     // Create the script element
    //     const script: any = document.createElement('script');
    //     script.id = 'embedder-inline-embed-widget-2';
    //     script.type = 'text/javascript';
    //     script.async = true;
    //     script.src = "https://embedder.upraise.io/ext/widget/inline_embed.js?embedType=2&widgetId=4aff3be8-bd17-47dd-ad3b-9db5d830abcb&clientKey=a5a93045-821d-3468-a24b-7f08f9bc8ccb";

    //     script.onload = script.onreadystatechange = function () {
    //         if (!this.readyState || this.readyState === 'complete' || this.readyState === 'loaded') {
    //             // Initialize the widget after script load
    //             // @ts-ignore
    //             new (window as any).embedConfluenceWidget();
    //         }
    //     };

    //     // Append the script to the div with id 'help'
    //     const helpDiv = document.getElementById('help');
    //     if (helpDiv) {
    //         helpDiv.appendChild(script);
    //     }

    //     // Cleanup function to remove the script and any widget elements
    //     return () => {
    //         if (helpDiv) {
    //             helpDiv.innerHTML = ''; // Clears any content injected by the script
    //         }
    //     };
    // }, []); // The empty dependency array ensures this effect runs once on mount/unmount

    // if (!confluenceLink) {
    //     return (
    //         <Card title='Documentation'>
    //             <Empty description="No documentation available" />
    //         </Card>
    //     );
    // }

    // https://embedder.upraise.io/ext/widget/inline_embed.js?embedType=2&widgetId=4aff3be8-bd17-47dd-ad3b-9db5d830abcb&clientKey=a5a93045-821d-3468-a24b-7f08f9bc8ccb

    return (
        <Card title='Documentation' id="help">
            {/* The script will populate content here */}
            <iframe src={confluenceLink} style={{ width: "100%", height: "1200px", border: "none", backgroundColor: "none" }}></iframe>
        </Card>
    );
}
