import { Button, Card, Col, DatePicker, Empty, List, Row, Select, Skeleton, Spin, Tabs, Tooltip, Typography, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { setBpValue, setSelectedTimePeriod, setSelectedTimezone } from '../../redux/slices/powerSlice';
import OnStatusImage from "../../assets/transparent-green-circle.png";
import OffStatusImage from "../../assets/transparent-grey-circle.png";
import { DownloadOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { HEADERS, LOWFUEL, REFUELED, TIMEZONES, VERYLOWFUEL } from '../../utils/constants';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import { fetchAndFilterGraphData } from '../../redux/services/powerService';
import _ from 'lodash';
import generatorDetails from '../../assets/GeneratorDetails.json';
import { CSVLink } from 'react-csv';

export default function PowerIndividual() {

    const responsiveColForLiveStatus = {
        xxl: 8,
        xl: 8,
        lg: 8,
        md: 8,
        sm: 24,
        xs: 24,
    };

    const responsiveColForLiveStatusMid = {
        xxl: 23,
        xl: 23,
        lg: 23,
        md: 23,
        sm: 24,
        xs: 24,
    };

    const responsiveColForLiveValues = {
        xxl: 16,
        xl: 16,
        lg: 16,
        md: 16,
        sm: 24,
        xs: 24,
    };

    const responsiveColDaySelection = {
        xxl: 10,
        xl: 10,
        lg: 10,
        md: 24,
        sm: 24,
        xs: 24,
    };

    const responsiveColBPValue = {
        xxl: 5,
        xl: 5,
        lg: 5,
        md: 8,
        sm: 8,
        xs: 24,
    };

    const responsiveCol = { xxl: 5, xl: 5, lg: 5, md: 8, sm: 8, xs: 24 };

    const responsiveColDownload = {
        xxl: 4,
        xl: 4,
        lg: 4,
        md: 8,
        sm: 8,
        xs: 24,
    };

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const dispatch = useDispatch<AppDispatch>();
    const { TabPane } = Tabs;
    const { customerSites: siteData, selectedCustomerName, customerSites, selectedSite } = useSelector((state: RootState) => state.userInfo);
    const { liveDataArray, selectedGeneratorId, isLoadingBPLiveData, graphData, loading: isLoadingGeneratorList, graphDataLoading: isLoadingGraphData, selectedTimePeriod, selectedTimezone, bpValueArray, bpValue, generatorList } = useSelector((state: RootState) => state.power);
    const filteredLiveDataArray = liveDataArray.filter(item => item.equipment_id === selectedGeneratorId);
    const filteredBpSiteHistory = graphData.filter(item => item.equipment_id === selectedGeneratorId);
    // const [generatorList, setGeneratorList] = useState<any>([]);

    const { RangePicker } = DatePicker;
    const { Option } = Select;
    const [dateRange, setDateRange] = useState<any>(null);

    const [activeTab, setActiveTab] = useState<string>('1'); // State variable to manage active tab
    const [fromDate, setFromDate] = useState<any>(null);
    const [toDate, setToDate] = useState<any>(null);


    const sortedData = [...filteredLiveDataArray].sort((a, b) =>
        a.value_name.localeCompare(b.value_name)
    );

    const binaryTypeBPValues = sortedData.filter(
        (item) => item.value_type === "binary"
    );

    const analogTypeBPValues = sortedData.filter(
        (item) => item.value_type === "analog"
    );

    const halfLengthOfLiveBPValues = Math.ceil(analogTypeBPValues.length / 2);
    const firstHalfOfLiveDataArray = analogTypeBPValues.slice(
        0,
        halfLengthOfLiveBPValues
    );

    const secondHalfOfLiveDataArray = analogTypeBPValues.slice(
        halfLengthOfLiveBPValues
    );

    const handleLiveItemClick = (selectedBpValue: any) => {
        setActiveTab('2'); // Switch to TabPane 2
        dispatch(setBpValue(selectedBpValue));

    }

    useEffect(() => {
        if (selectedTimePeriod != "date") {
            getDateTimeRange(selectedTimePeriod);
        }
    }, [selectedTimePeriod]);

    function getDateTimeRange(option: any) {
        var currentDate = new Date();
        var startDate, endDate;

        switch (option) {
            case "Last 24 hours":
                startDate = new Date(currentDate);
                startDate.setHours(currentDate.getHours() - 24);
                endDate = new Date(currentDate);
                break;
            case "Today":
                startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59, 999);
                break;
            case "Last 7 Days":
                startDate = new Date(currentDate);
                startDate.setDate(currentDate.getDate() - 7);
                endDate = new Date(currentDate);
                break;
            case "Last 30 Days":
                startDate = new Date(currentDate);
                startDate.setDate(currentDate.getDate() - 30);
                endDate = new Date(currentDate);
                break;
            case "This Week":
                var currentDayOfWeek = currentDate.getDay();
                var startOffset = (currentDayOfWeek === 0 ? -6 : 1) - currentDayOfWeek;
                startDate = new Date(currentDate);
                startDate.setDate(currentDate.getDate() + startOffset);
                endDate = new Date(startDate);
                endDate.setDate(startDate.getDate() + 6);
                break;
            case "This Month":
                startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59, 999);
                break;
            default:
                startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                endDate = new Date(currentDate);
                break;
        }

        // Setting fromDate and toDate for the API call to plot graph data based on the time period changes

        setFromDate(startDate);
        setToDate(endDate);
    }

    const onChangeTab = (key: string) => {
        setActiveTab(key);
    };

    const staticLiveData: { value_name: string }[] = [
        {
            value_name: 'Common Alarm',
        },
        {
            value_name: 'Emergency Stop',
        },
        {
            value_name: 'Not in Auto',
        },
        {
            value_name: 'Running',
        },
        {
            value_name: 'Shutdown Alarm',
        },
        {
            value_name: 'Supplying Load',
        },
        {
            value_name: 'Warning Alarm',
        },
    ];

    const firstHalfOfStaticValues: { value_name: string }[] = [
        {
            value_name: 'Amps A',
        },
        {
            value_name: 'Amps C',
        },
        {
            value_name: 'Coolant Temp',
        },
        {
            value_name: 'Frequency',
        },
        {
            value_name: 'kVAR',
        },
        {
            value_name: 'Oil Pressure',
        },
        {
            value_name: 'RPM',
        },
        {
            value_name: 'Volts BC',
        },
    ];

    const secondHalfOfStaticValues: { value_name: string }[] = [
        {
            value_name: 'Amps B',
        },
        {
            value_name: 'Battery Voltage',
        },
        {
            value_name: 'Engine Hours',
        },
        {
            value_name: 'Fuel Level',
        },
        {
            value_name: 'kW',
        },
        {
            value_name: 'Power Factor',
        },
        {
            value_name: 'Volts AB',
        },

        {
            value_name: 'Volts CA',
        },
    ];

    const handleTimePeriodChange = (timePeriod: any) => {
        dispatch(setSelectedTimePeriod(timePeriod));
    };

    const filterDateTime = (range: any) => {
        setDateRange(range);
        // Returns a string representing this date in the date time string format,
        const fromDate = range[0].toISOString();
        const toDate = range[1].toISOString();

        setFromDate(fromDate);
        setToDate(toDate);
    };

    const filterBPValue = (value: any) => {
        dispatch(setBpValue(value));
    };

    const updateTimezone = (timezone: any) => {
        dispatch(setSelectedTimezone(timezone));
    };

    const findGeneratorName = (equipmentId: any) => {
        const generator = generatorList.find((generator: any) => generator.equipment_id === equipmentId);
        return generator ? generator.equipment_name : 'Unknown Generator';
    };


    const filteredBpSiteHistoryWithGeneratorName = filteredBpSiteHistory.map(item => ({
        ...item,
        generator_name: findGeneratorName(item.equipment_id)
    }));

    let largestValue: number = 100;
    let maxValue: any = -Infinity;
    let latestValueDatetime: any = null;
    let formattedLatestValueDatetime: any = null;


    const formattedFuelHistory = filteredBpSiteHistory?.map((hist) => {
        const formattedDatetime = dayjs(hist.value_datetime)
            .tz(selectedTimezone)
            .format("MM-DD HH:mm");
        return formattedDatetime;
    });

    const data: { labels: any; datasets: any[] } = {
        labels: formattedFuelHistory,
        datasets: [
            {
                label: bpValue,
                data: filteredBpSiteHistory
                    .map(value => value.value === -99 ? null : value.value),
                borderColor: "blue",
                backgroundColor: "blue",
                pointBackgroundColor: function (context: any) {
                    var index = context.dataIndex;
                    var value = context.dataset.data[index];
                    return value <= VERYLOWFUEL
                        ? "red" // draw peak values in red
                        : value <= LOWFUEL &&
                            value > VERYLOWFUEL
                            ? "orange"
                            : value >= REFUELED
                                ? "green"
                                : "blue";
                },
                pointBorderColor: function (context: any) {
                    var index = context.dataIndex;
                    var value = context.dataset.data[index];
                    return value <= VERYLOWFUEL
                        ? "red" // draw peak values in red
                        : value <= LOWFUEL &&
                            value > VERYLOWFUEL
                            ? "orange"
                            : value >= REFUELED
                                ? "green"
                                : "blue";
                },
            },
            ...(bpValue === "Fuel Level"
                ? [
                    {
                        label: "Very low",
                        borderColor: "red",
                        backgroundColor: "red",
                    },
                    {
                        label: "low",
                        borderColor: "orange",
                        backgroundColor: "orange",
                    },
                    {
                        label: "Refueled",
                        borderColor: "green",
                        backgroundColor: "green",
                    },
                ]
                : []),
        ],
    };

    for (const obj of filteredBpSiteHistory) {
        // For Fuel level only we are setting the max value as 100 in graph y axis.
        if (bpValue === "Fuel Level") {
            largestValue = 100;
        } else {
            if (obj.value > maxValue) {
                maxValue = obj.value;
            }
            largestValue = maxValue > 0 ? maxValue : 100;
        }
    }

    const emptyData = {
        datasets: [{
            data: [],
            label: 'No data available',
            borderColor: 'rgba(255, 99, 132, 1)',
            backgroundColor: 'rgba(54, 162, 235, 0.2)', // Blue color fill
            borderWidth: 1,
            fill: true
        }]
    };

    function getTitle() {
        // Add your conditional logic here to determine the title based on certain conditions        
        return "BP History Chart";

    }

    useEffect(() => {
        if (_.isEmpty(bpValue))
            dispatch(setBpValue(bpValueArray[0]))
    }, [bpValueArray])

    function setMaxValueForXaxis() {
        for (const dataPoint of filteredBpSiteHistory) {
            const valueDatetime = new Date(dataPoint.value_datetime);
            if (!latestValueDatetime || valueDatetime > latestValueDatetime) {
                latestValueDatetime = valueDatetime;
            }
        }
        // Convert the maximum value_datetime to the desired format (e.g., "MM-DD HH:mm")
        return (formattedLatestValueDatetime = dayjs(latestValueDatetime)
            .tz(selectedTimezone)
            .format("MM-DD HH:mm"));
    }

    const options: { [key: string]: any } = {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top",
                labels: {
                    boxHeight: 10, // Adjust the height of legend labels
                    boxWidth: 10, // Adjust the width of legend labels
                },
            },
            title: {
                display: true,
                text: getTitle(),
            },
        },
        scales: {
            x: {
                ticks: {
                    precision: 0,
                    maxTicksLimit: 10, // Set the maximum number of ticks you want on the x-axis
                    display: true,
                },
                max: setMaxValueForXaxis(),
            },
            y: {
                ticks: {
                    precision: 1,
                    maxTicksLimit: 10, // Set the maximum number of ticks you want on the y-axis
                    padding: 0, // Adjust the padding between ticks
                    display: true,
                },
                min: 0,
                max: largestValue,
                padding: {
                    top: 0, // Adjust the padding at the top of the y-axis
                    bottom: 0, // Adjust the padding at the bottom of the y-axis
                },
            },
        },
    };

    useEffect(() => {
        if (customerSites && !([undefined, null, ''].includes(selectedGeneratorId + '')) && selectedSite && activeTab === '2') {
            dispatch(fetchAndFilterGraphData({ selectedCustomerName, avail_sites: customerSites, selectedSite, fromDate, toDate, bpValue, selectedGeneratorId: selectedGeneratorId ?? 0 }));
        }
    }, [fromDate, toDate, selectedSite, bpValue, selectedGeneratorId, activeTab]);

    return (
        <Tabs
            defaultActiveKey="1"
            onChange={onChangeTab}
            activeKey={activeTab}
        >
            <TabPane tab="Live" key="1">
                {filteredLiveDataArray.length != 0 && isLoadingBPLiveData === false ? (
                    <Row
                        style={{
                            width: "100%",
                        }}
                    >
                        <Col {...responsiveColForLiveStatus}>
                            <Row>
                                <Col
                                    {...responsiveColForLiveStatusMid}
                                    style={{
                                        border: "1px solid rgb(232 232 232)",
                                        borderRadius: "10px",
                                        boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)",
                                        background: colorBgContainer
                                    }}
                                >
                                    <Card title="Status" bordered={false} size="small" style={{ height: "auto" }}>
                                        <List
                                            dataSource={binaryTypeBPValues}
                                            renderItem={(item) => {
                                                // Find the corresponding data point in generatorDetails
                                                const matchingPoint = generatorDetails.find(point => point.DataPointName === item.value_name);

                                                return (
                                                    <List.Item
                                                        key={item.value_name}
                                                        onClick={() => handleLiveItemClick(item.value_name)}
                                                        style={{ lineHeight: ".5", cursor: "pointer" }}
                                                    >
                                                        <div>
                                                            <img
                                                                src={item.value === 0 ? OffStatusImage : OnStatusImage}
                                                                height="30px"
                                                                alt={item.value === 0 ? "Status Off" : "Status On"} // Added alt attribute for accessibility
                                                            />
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            {item.value_name}
                                                            {/* Information icon with tooltip */}
                                                            {matchingPoint && (
                                                                <Tooltip color='blue' title={matchingPoint.Description}>
                                                                    <InfoCircleOutlined style={{ marginLeft: 5, color: '#1890ff' }} />
                                                                </Tooltip>
                                                            )}
                                                        </div>
                                                        <div>
                                                            {item.value === 0 ? "(Off)" : "(On)"}
                                                        </div>
                                                    </List.Item>
                                                );
                                            }}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </Col>

                        <Col {...responsiveColForLiveValues}>
                            <Row style={{ height: "100%" }}>
                                <Col
                                    span={24}
                                    style={{
                                        border: "1px solid rgb(232 232 232)",
                                        borderRadius: "10px",
                                        boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)",
                                        background: colorBgContainer,
                                    }}
                                >
                                    <Card title="Values" bordered={false} size="small">
                                        <Row>
                                            <Col span={8}>
                                                <List
                                                    dataSource={firstHalfOfLiveDataArray}
                                                    renderItem={(item) => {
                                                        // Find the corresponding data point in generatorDetails
                                                        const matchingPoint = generatorDetails.find(point => point.DataPointName === item.value_name);

                                                        return (
                                                            <List.Item
                                                                key={item.value_name}
                                                                onClick={() => handleLiveItemClick(item.value_name)}
                                                                style={{ lineHeight: "1.2", cursor: "pointer" }}
                                                            >
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    {item.value_name}
                                                                    {/* Information icon with tooltip */}
                                                                    {matchingPoint && (
                                                                        <Tooltip color='blue' title={matchingPoint.Description}>
                                                                            <InfoCircleOutlined style={{ marginLeft: 5, color: '#1890ff' }} />
                                                                        </Tooltip>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    {item.value === -99
                                                                        ? "OFF"
                                                                        : item.value <= -1000
                                                                            ? "N/A"
                                                                            : item.value}
                                                                </div>
                                                            </List.Item>
                                                        );
                                                    }}
                                                />
                                            </Col>
                                            <Col span={4}></Col>
                                            <Col span={8}>
                                                <List
                                                    dataSource={secondHalfOfLiveDataArray}
                                                    renderItem={(item) => {
                                                        // Find the corresponding data point in generatorDetails
                                                        const matchingPoint = generatorDetails.find(point => point.DataPointName === item.value_name);

                                                        return (
                                                            <List.Item
                                                                key={item.value_name}
                                                                onClick={() => handleLiveItemClick(item.value_name)}
                                                                style={{ lineHeight: "1.2" }}
                                                            >
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    {item.value_name}
                                                                    {/* Information icon with tooltip */}
                                                                    {matchingPoint && (
                                                                        <Tooltip color='blue' title={matchingPoint.Description}>
                                                                            <InfoCircleOutlined style={{ marginLeft: 5, color: '#1890ff' }} />
                                                                        </Tooltip>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    {item.value === -99
                                                                        ? "OFF"
                                                                        : item.value <= -1000
                                                                            ? "N/A"
                                                                            : item.value}
                                                                </div>
                                                            </List.Item>
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                ) : (
                    <Row
                        style={{
                            width: "100%",
                        }}
                    >
                        {isLoadingBPLiveData && <Col span={24}>
                            <Typography.Paragraph>
                                <p><Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} /> &nbsp;Loading live data</p>
                            </Typography.Paragraph>
                        </Col>}
                        {<Col {...responsiveColForLiveStatus}>
                            <Row>
                                <Col
                                    {...responsiveColForLiveStatusMid}
                                    style={{
                                        border: "1px solid rgb(232 232 232)",
                                        borderRadius: "10px",
                                        boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)",
                                        background: colorBgContainer
                                    }}
                                >
                                    <Card title="Status" bordered={false} size="small" style={{ minHeight: "55vh" }}>
                                        <List>
                                            {staticLiveData.map(item => {
                                                // Find the corresponding data point in the generatorDetails
                                                const matchingPoint = generatorDetails.find(point => point.DataPointName === item.value_name);

                                                return (
                                                    <List.Item key={item.value_name} style={{ lineHeight: ".5", cursor: "pointer" }}>
                                                        <div>
                                                            <img src={OffStatusImage} height="30px" alt="Status Off" />
                                                        </div>
                                                        <Row>
                                                            <Col style={{ display: 'flex', alignItems: 'center' }}>
                                                                {item.value_name}
                                                                {/* Information icon with tooltip */}
                                                                {matchingPoint && (
                                                                    <Tooltip color='blue' title={matchingPoint.Description}>
                                                                        <InfoCircleOutlined style={{ marginLeft: 5, color: '#1890ff' }} />
                                                                    </Tooltip>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                        <div>
                                                            {"(Off)"}
                                                        </div>
                                                    </List.Item>
                                                );
                                            })}
                                        </List>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>}

                        <Col {...responsiveColForLiveValues}>
                            <Row style={{ height: "100%" }}>
                                <Col
                                    span={24}
                                    style={{
                                        border: "1px solid rgb(232 232 232)",
                                        borderRadius: "10px",
                                        boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)",
                                        background: colorBgContainer,
                                    }}
                                >
                                    <Card title="Values" bordered={false} size="small">
                                        <Row>
                                            <Col span={8}>
                                                <List
                                                    dataSource={firstHalfOfStaticValues}
                                                    renderItem={(item) => {
                                                        // Find the corresponding data point in generatorDetails
                                                        const matchingPoint = generatorDetails.find(point => point.DataPointName === item.value_name);

                                                        return (
                                                            <List.Item
                                                                key={item.value_name}
                                                                onClick={() => handleLiveItemClick(item.value_name)}
                                                                style={{ lineHeight: "1.2", cursor: "pointer" }}
                                                            >
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    {item.value_name}
                                                                    {/* Information icon with tooltip */}
                                                                    {matchingPoint && (
                                                                        <Tooltip color='blue' title={matchingPoint.Description}>
                                                                            <InfoCircleOutlined style={{ marginLeft: 5, color: '#1890ff' }} />
                                                                        </Tooltip>
                                                                    )}
                                                                </div>
                                                                <div>N/A</div>
                                                            </List.Item>
                                                        );
                                                    }}
                                                />
                                            </Col>
                                            <Col span={4}></Col>
                                            <Col span={8}>
                                                <List
                                                    dataSource={secondHalfOfStaticValues}
                                                    renderItem={(item) => {
                                                        // Find the corresponding data point in generatorDetails
                                                        const matchingPoint = generatorDetails.find(point => point.DataPointName === item.value_name);

                                                        return (
                                                            <List.Item
                                                                key={item.value_name}
                                                                onClick={() => handleLiveItemClick(item.value_name)}
                                                                style={{ lineHeight: "1.2" }}
                                                            >
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    {item.value_name}
                                                                    {/* Information icon with tooltip */}
                                                                    {matchingPoint && (
                                                                        <Tooltip color='blue' title={matchingPoint.Description}>
                                                                            <InfoCircleOutlined style={{ marginLeft: 5, color: '#1890ff' }} />
                                                                        </Tooltip>
                                                                    )}
                                                                </div>
                                                                <div>N/A</div>
                                                            </List.Item>
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </TabPane>
            <TabPane tab="Trend" key="2">
                {<div>
                    <Card size="small" style={{ background: colorBgContainer }}>
                        <Row justify="start">
                            <Col span={24}>
                                <Row>
                                    <Col {...responsiveColDaySelection}>
                                        <Typography.Paragraph style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', alignItems: 'center' }}>
                                            Filter by:
                                            <Select
                                                value={selectedTimePeriod}
                                                style={{ width: 150 }}
                                                onChange={handleTimePeriodChange}
                                                options={[
                                                    { value: "Last 24 hours", label: "Last 24 hours" },
                                                    { value: "Today", label: "Today" },
                                                    { value: "Last 7 Days", label: "Last 7 Days" },
                                                    { value: "Last 30 Days", label: "Last 30 Days" },
                                                    { value: "This Week", label: "This Week" },
                                                    { value: "This Month", label: "This Month" },
                                                    { value: "date", label: "Custom" },
                                                ]}
                                            />
                                            {selectedTimePeriod === "date" && (
                                                <RangePicker
                                                    showTime
                                                    allowClear={false}
                                                    value={dateRange}
                                                    format="MM/DD/YYYY HH:mm"
                                                    onChange={filterDateTime}
                                                    style={{ width: 300 }}
                                                />
                                            )}
                                        </Typography.Paragraph>
                                    </Col>
                                    <Col {...responsiveColBPValue}>
                                        <Typography.Paragraph>
                                            Display: &nbsp;&nbsp;
                                            <Select
                                                showSearch  // Enable search functionality
                                                value={bpValueArray.includes(bpValue) ? bpValue : bpValueArray[0]}
                                                style={{ width: 155 }}
                                                onChange={filterBPValue}
                                            >
                                                {bpValueArray
                                                    .slice()
                                                    .sort((a, b) => {
                                                        // Custom sort function, if necessary. For ascending order:
                                                        return a.localeCompare(b);
                                                        // For descending order:
                                                        // return b.localeCompare(a);
                                                    })
                                                    .map((option) => (
                                                        <Option key={option} value={option}>
                                                            {option}
                                                        </Option>
                                                    ))}
                                            </Select>
                                        </Typography.Paragraph>
                                    </Col>
                                    <Col  {...responsiveCol}>
                                        <Typography.Paragraph>
                                            Timezone: &nbsp;&nbsp;
                                            <Select
                                                defaultValue={selectedTimezone}
                                                style={{ width: 120 }}
                                                onChange={updateTimezone}
                                                showSearch
                                                optionFilterProp="label"
                                                filterOption={(input, option: any) =>
                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {TIMEZONES.map((timezone) => (
                                                    <Option key={timezone.value} value={timezone.value} label={timezone.label}>
                                                        {timezone.label}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Typography.Paragraph>
                                    </Col>
                                    <Col {...responsiveColDownload}>
                                        <CSVLink
                                            data={filteredBpSiteHistoryWithGeneratorName}
                                            headers={HEADERS}
                                            className="csvLink"
                                            filename={graphData[0]?.equipment_name ? `${selectedCustomerName}_${selectedSite}_${graphData[0]?.equipment_name}.csv` : "data.csv"}
                                        >
                                            <Button loading={isLoadingGraphData} type="primary" disabled={isLoadingGraphData || graphData.length === 0}>
                                                <DownloadOutlined style={{ fontSize: "20px" }} />
                                                Download CSV
                                            </Button>
                                        </CSVLink>
                                    </Col>
                                </Row>
                            </Col>
                            {!isLoadingGraphData && !isLoadingGeneratorList && graphData.length > 0 && <Col
                                span={24}
                                style={{
                                    height: "500px",
                                    width: "100%",
                                    overflow: "auto",
                                    marginTop: "10px",
                                }}
                            >
                                <div style={{ backgroundColor: data.datasets[0].data.length > 0 ? 'transparent' : '#d0dff9', height: "100%" }}>
                                    <Line
                                        options={{
                                            ...options,
                                            plugins: {
                                                ...options.plugins,
                                                title: {
                                                    display: data.datasets[0].data.length > 0,
                                                    text: getTitle(),
                                                },
                                                legend: {
                                                    ...options.plugins.legend,
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    ...options.scales.x,
                                                    ticks: {
                                                        ...options.scales.x.ticks,
                                                        display: data.datasets[0].data.length > 0,
                                                    },
                                                },
                                                y: {
                                                    ...options.scales.y,
                                                    ticks: {
                                                        ...options.scales.y.ticks,
                                                        display: data.datasets[0].data.length > 0,
                                                    },
                                                    // Add suggestedMax to create space above the maximum value
                                                    max: Math.max(...data.datasets[0].data) < 0 ? 0 : parseFloat((Math.max(...data.datasets[0].data) * 1.1).toFixed(2)),
                                                    min: Math.max(...data.datasets[0].data) <= 0 ? 1 : 0
                                                },
                                            },
                                        }}
                                        data={data.datasets[0].data.length > 0 ? data : emptyData}
                                        className="lineGraph"
                                    />
                                </div>
                            </Col>}
                        </Row>
                    </Card>
                </div>}
                {!isLoadingGraphData && !isLoadingGeneratorList && graphData.length === 0 && (
                    <Col span={24} className='emptyStyle'>
                        <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description={selectedTimePeriod == "date"
                            ? `No ${bpValue ? bpValue : ''} data found for the given period ( ${dayjs(fromDate).format("MM/DD/YYYY")} - ${dayjs(toDate).format("MM/DD/YYYY")} )`
                            : `No ${bpValue ? bpValue : ''} data found for ${selectedTimePeriod ? selectedTimePeriod : 'given time period'}`} />
                    </Col>
                )}
                {(isLoadingGraphData || isLoadingGeneratorList) &&
                    <div style={{ width: "100%" }}>
                        <Skeleton active loading={(isLoadingGraphData || isLoadingGeneratorList)} paragraph={{ rows: 8 }} />
                    </div>}
            </TabPane>
        </Tabs>
    )
}