import { Col, Collapse, Row } from "antd";
import React, { useEffect, useState } from "react";
import "antd/dist/reset.css";
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { CameraSearchToolBar, EbImageRow } from "./CameraTools";
import { ConvertDate } from "./CameraTable";
import { fetchPictureListByCamera } from "../../redux/services/cameraService";
import { AppDispatch, RootState } from "../../store";
import { Root } from "react-dom/client";

interface CameraInfo {
    id: string;
    createdAt: string;
    cameraName: string;
    truckId: string;
    pictureType: string;
    picturePath: string;
    pictureSize: number;
    detectedAs: string;
}

// Get unique days from records
function uniqueDays(fullList: CameraInfo[]): string[] {
    const uniqueDates = new Set<string>();
    fullList.forEach(row => {
        const date = new Date(row.createdAt);
        const dateString = date.toISOString().split('T')[0];
        uniqueDates.add(dateString);
    });
    return Array.from(uniqueDates);
}

// Filter records by target date
function filterRecordsByDate(records: CameraInfo[], targetDate: string): CameraInfo[] {
    return records.filter(row => {
        if (!row || !row.createdAt) return false;
        const recordDate = new Date(row.createdAt).toISOString().split('T')[0];
        return recordDate === targetDate;
    });
}

// Clean and structure the records
function cleanListOfRecords(fullList: CameraInfo[]): CameraInfo[] {
    return fullList.map(row => ({
        id: row.id,
        createdAt: ConvertDate(row.createdAt),
        cameraName: row.cameraName,
        truckId: row.truckId,
        pictureType: row.pictureType,
        picturePath: row.picturePath,
        pictureSize: row.pictureSize,
        detectedAs: row.detectedAs
    }));
}

// Generate a collapse row with images
function RowColgenerateRowAndColContainingImages(index: number, rowMsg: string, imageData: CameraInfo[]) {
    return (
        <Row align='stretch' wrap={true} >
            {imageData.map((dbrow, i) => (
                <Col key={i} span={4}>
                    <EbImageRow row={dbrow} />
                </Col>
            ))}
        </Row>
    );
}

// Generate multiple collapse rows based on days
function RowColgenerateManyCollapseRows(fullList: CameraInfo[], uniqueListOfDays: string[]) {
    const ret: any[] = [];
    const imageArray = uniqueListOfDays.map(aDay => {
        const dirtyList = filterRecordsByDate(fullList, aDay);
        const listOfImagesForAGivenDay = cleanListOfRecords(dirtyList);
        const imageCount = listOfImagesForAGivenDay.length;
        const rowMsg = `${aDay} - ${imageCount} images`;
        return RowColgenerateRowAndColContainingImages(0, rowMsg, listOfImagesForAGivenDay);
    });

    uniqueListOfDays.forEach((day, index) => {
        const listOfImagesForAGivenDay = filterRecordsByDate(fullList, day);
        const imageCount = listOfImagesForAGivenDay.length;
        const me = {
            key: index,
            label: `${day} - ${imageCount} images`,
            children: [imageArray[index]]
        };
        ret.push(me);
    });

    return ret;
}

const CameraCardFunctions = {
    refreshDataSet: {} as () => void,
};

function Cameras() {
    const [reloadDataFromClick, setCount] = useState(0);
    const dispatch = useDispatch<AppDispatch>();

    CameraCardFunctions.refreshDataSet = () => {
        console.log("reloading CameraCard dataset");
        setCount(prev => prev + 1);
    };

    const selectedCustomerName = useSelector((state: RootState) => state.userInfo.selectedCustomerName);
    const selectedSite = useSelector((state: RootState) => state.userInfo.selectedSite);

    useEffect(() => {
        if (!_.isEmpty(selectedSite) && !_.isEmpty(selectedCustomerName)) {
            dispatch(fetchPictureListByCamera({ selectedCustomerName, selectedSite }));
        }
    }, [reloadDataFromClick, selectedSite, selectedCustomerName, dispatch]);

    const CameraInfoList = useSelector((state: any) => state.camera.cameraInfoList);
    if (CameraInfoList.length === 0) return null;

    const uniqueListOfDays = uniqueDays(CameraInfoList);
    const collapseList = RowColgenerateManyCollapseRows(CameraInfoList, uniqueListOfDays);

    return (
        <React.Fragment>
            <CameraSearchToolBar CameraCardFunctions={CameraCardFunctions} />
            <Collapse accordion items={collapseList} defaultActiveKey={['0']} />
        </React.Fragment>
    );
}

export default Cameras;
