import React, { useState, useEffect } from "react";
import { Tooltip as AntToolTip, Skeleton, Spin } from 'antd'
import {
    DatePicker,
    Row,
    Col,
    Typography,
    Button,
    Select,
    Card,
    theme,
    Tag, Space, Progress
} from "antd";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { FUEL, GENERAL, LOWFUEL, REFUELED, TIMEZONES, VERYLOWFUEL } from "../../utils/constants";
import dayjs from "dayjs";
import { setBpValue, setSelectedGeneratorId, setSelectedTimePeriod, setSelectedTimezone } from "../../redux/slices/powerSlice";
import { fetchAndFilterGraphData } from "../../redux/services/powerService";
import _ from "lodash";
const Fuel = () => {
    const responsiveCol = { xxl: 8, xl: 8, lg: 8, md: 8, sm: 24, xs: 24 };
    const { generatorList: generatorListfromAPI, loading: isLoadingGeneratorList, generatorListError, selectedGeneratorId, graphData: bpSitehistory, graphDataLoading, selectedTimePeriod, selectedTimezone } = useSelector((state: RootState) => state.power);
    const { selectedCustomerName, customerSites, selectedSite } = useSelector((state: RootState) => state.userInfo);
    const [generatorList, setGeneratorList] = useState<any[]>([]);
    const [dateRange, setDateRange] = useState<any>(null);
    const dispatch = useDispatch<AppDispatch>();
    const { fuelId } = useParams();
    const [fromDate, setFromDate] = useState<any>(dayjs().startOf('day'));
    const [toDate, setToDate] = useState<any>(dayjs().endOf('day'));
    const selectedSiteObj = customerSites.find((data) => data.name === selectedSite)
    const present_components: any = selectedSiteObj && selectedSiteObj?.preferences?.present_components;
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedGeneratorId !== null && !fuelId) {
            navigate(`/${selectedCustomerName}/${selectedSite}/${FUEL}/${selectedGeneratorId}`);
        }
    }, [selectedGeneratorId])

    useEffect(() => {
        if (fuelId && generatorList.length > 0 && !isLoadingGeneratorList) {
            if (generatorList.filter(item => item.value === Number(fuelId)).length > 0) {
                dispatch(setSelectedGeneratorId(Number(fuelId)))
            }
            else {
                dispatch(setSelectedGeneratorId(generatorList[0].value));
                navigate(`/${selectedCustomerName}/${selectedSite}/${FUEL}/${generatorList[0].value}`);
            }
        }
    }, [fuelId, generatorList])

    function getDateTimeRange(option: any) {
        var currentDate = new Date();
        var startDate, endDate;

        switch (option) {
            case "Last 24 hours":
                startDate = new Date(currentDate);
                startDate.setHours(currentDate.getHours() - 24);
                endDate = new Date(currentDate);
                break;
            case "Today":
                startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59, 999);
                break;
            case "Last 7 Days":
                startDate = new Date(currentDate);
                startDate.setDate(currentDate.getDate() - 7);
                endDate = new Date(currentDate);
                break;
            case "Last 30 Days":
                startDate = new Date(currentDate);
                startDate.setDate(currentDate.getDate() - 30);
                endDate = new Date(currentDate);
                break;
            case "This Week":
                var currentDayOfWeek = currentDate.getDay();
                var startOffset = (currentDayOfWeek === 0 ? -6 : 1) - currentDayOfWeek;
                startDate = new Date(currentDate);
                startDate.setDate(currentDate.getDate() + startOffset);
                endDate = new Date(startDate);
                endDate.setDate(startDate.getDate() + 6);
                break;
            case "This Month":
                startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59, 999);
                break;
            default:
                startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                endDate = new Date(currentDate);
                break;
        }

        // Setting fromDate and toDate for the API call to plot graph data based on the time period changes

        setFromDate(startDate);
        setToDate(endDate);
    }

    const handleEquipmentChange = (e: any) => {
        e && dispatch(setSelectedGeneratorId(e));
        e && navigate(`${e}`);
    };

    useEffect(() => {
        if (selectedGeneratorId && selectedSite && selectedTimePeriod && selectedGeneratorId === Number(fuelId)) {
            dispatch(fetchAndFilterGraphData({ selectedCustomerName: selectedCustomerName, avail_sites: customerSites, selectedSite: selectedSite, fromDate: fromDate?.toISOString(), toDate: toDate?.toISOString(), bpValue: 'Fuel Level', selectedGeneratorId: selectedGeneratorId ? selectedGeneratorId : 0 }));
        }
    }, [selectedGeneratorId, selectedSite, selectedTimePeriod, fuelId])

    const handleTimePeriodChange = (timePeriod: string) => {
        dispatch(setSelectedTimePeriod(timePeriod));
    };

    const { Option } = Select;

    const { RangePicker } = DatePicker;


    let latestValueDatetime: any = null;

    const filterDateTime = (range: any) => {
        setDateRange(range)
        // Returns a string representing this date in the date time string format,
        const fromDate = range[0];
        const toDate = range[1];

        setFromDate(fromDate)
        setToDate(toDate)
    };


    useEffect(() => {
        if (generatorListfromAPI) {
            setGeneratorList(generatorListfromAPI
                .filter(info => info.equipment_name !== null && !['EV Charger', 'EV_Charger'].includes(info.equipment_name)) // Filter out null equipment_name
                .map(info => ({
                    value: info.equipment_id,
                    label: info.equipment_name,
                })))
        }
    }, [generatorListfromAPI])

    const updateTimezone = (timezone: string) => {
        dispatch(setSelectedTimezone(timezone));
    };


    return (

        <Row justify="start" style={{ paddingBottom: "10px" }}>
            <Col span={24}>
                <Card title="Fuel History">
                    <Row justify="start" className="rowGraph" gutter={[16, 16]}>
                        <Col span={24}>
                            <Row>
                                <Col {...responsiveCol}>
                                    {((generatorList && generatorList[0]) || isLoadingGeneratorList) && <Select
                                        value={selectedGeneratorId}
                                        style={{ width: 120 }}
                                        allowClear={true}
                                        onClear={() => {
                                            dispatch(setSelectedGeneratorId(null)
                                            );
                                            navigate(`/${selectedCustomerName}/${selectedSite}/${FUEL}`);
                                        }}
                                        options={generatorList}
                                        loading={isLoadingGeneratorList}
                                        onChange={handleEquipmentChange}
                                    />}
                                </Col>
                                <Col {...responsiveCol}>
                                    <Typography.Paragraph style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', alignItems: 'center' }}>
                                        Filter by:
                                        <Select
                                            value={selectedTimePeriod}
                                            style={{ width: 150 }}
                                            onChange={handleTimePeriodChange}
                                            options={[
                                                { value: "Last 24 hours", label: "Last 24 hours" },
                                                { value: "Today", label: "Today" },
                                                { value: "Last 7 Days", label: "Last 7 Days" },
                                                { value: "Last 30 Days", label: "Last 30 Days" },
                                                { value: "This Week", label: "This Week" },
                                                { value: "This Month", label: "This Month" },
                                                { value: "date", label: "Custom" },
                                            ]}
                                        />
                                        {selectedTimePeriod === "date" && (
                                            <RangePicker
                                                showTime
                                                value={dateRange}
                                                format="MM/DD/YYYY HH:mm"
                                                onChange={filterDateTime}
                                                style={{ width: 300 }}
                                            />
                                        )}
                                    </Typography.Paragraph>
                                </Col>

                                <Col {...responsiveCol}>
                                    <Typography.Paragraph>
                                        Timezone: &nbsp;&nbsp;
                                        <Select
                                            defaultValue={selectedTimezone}
                                            style={{ width: 120 }}
                                            onChange={updateTimezone}
                                            showSearch
                                            optionFilterProp="label"
                                            filterOption={(input: string, option: any) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {TIMEZONES.map((timezone) => (
                                                <Option key={timezone.value} value={timezone.value} label={timezone.label}>
                                                    {timezone.label}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Typography.Paragraph>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div style={{ height: '20px' }}></div>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Outlet />
                        </Col>
                    </Row>
                </Card>

            </Col>
        </Row>
    )
}

export default Fuel;